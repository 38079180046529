import React, { useContext, useEffect, useState } from 'react'
import Header from '../comman/Header'
import LeftSidebar from '../comman/LeftSidebar'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ProjectContext } from '../../ProjectContext';
import axios from 'axios';

export default function Stake() {
    const { link_ref_address } = useParams();

    const { pkgs, dbuser, account, balance, getudata, connectMetamask, esigner, formatAddress, library, toastError, settings, ethers, toastSuccess, getuserDB } = useContext(ProjectContext)

    const [showPackage, setShowPackage] = useState(false);
    const [spkg, setspkg] = useState(null);
    const [amount, setamount] = useState(0);
    const [trdone, settrdone] = useState(false);
    const [hash, sethash] = useState("");
    const [referral, setreferral] = useState("");
    var navigate = useNavigate();

    const onDeposit = async () => {
        settrdone(true);
        sethash("");
        if (!account) {
            connectMetamask();
            toastError("Connect first");
            settrdone(false);
            return;
        }
        if (!referral) {
            toastError("Enter Referral Address");
            settrdone(false);
            return;
        }
        if (dbuser === null) {
            var data = await getudata(referral);
            if (data.data.error) {
                toastError("Invalid Referral Address");
                settrdone(false);
                return "";
            }
            if (data.data.data.status === false) {
                toastError("Referral not active");
                settrdone(false);
                return "";
            }
        } else if (dbuser.restake === false) {
            toastError("You cannot restake now");
            settrdone(false);
            return "";
        }
        console.log("dassd", amount);

        if (!spkg) {
            toastError("Select Package !");
            settrdone(false);
            return;
        }
        // if (!tandc) {
        //     toastError("Accept terms and conditions");
        //     settrdone(false);
        //     return;
        // }

        let contract = new library.Contract(
            settings.contract,
            JSON.parse(settings.contract_abi),
            esigner
        );
        let token_contract = new library.Contract(
            settings.token,
            JSON.parse(settings.token_abi),
            esigner
        );
        const allowance = await token_contract.allowance(
            account,
            settings.contract
        );

        async function main() {
            // var gsl = await stakcontract//   .stake(ethers.utils.parseEther(`${amount}`), incappass, referral)
            //   .estimateGas( { from: account, value: `${Number(calcstakingfee) + 1000000000}` });
            //   console.log("gasghd ",gsl,await library.eth.getGasPrice()
            //   );
            try {
                // var stk = await contract.stake(
                //     ethers.utils.parseEther(`${amount}`),
                //     referral
                // );
                const randomHash = Array.from(crypto.getRandomValues(new Uint8Array(16))).map(byte => byte.toString(16).padStart(2, '0')).join('');

                // const receipt = await stk.wait();
                // sethash(receipt.transactionHash);
                sethash(randomHash);
                
                await axios
                    .post(process.env.REACT_APP_BACKEND_LINK, {
                        method: "transaction",
                        submethod: "insert",
                        key: process.env.REACT_APP_KEY,
                        address: account,
                        ref_address: referral,
                        pkg_id: spkg._id,
                        hash: randomHash,
                        // hash: receipt.transactionHash,
                        token: spkg.amount,
                        amount: spkg.amount,
                        rate: 1,
                    })
                    .then((res) => {
                        console.log("res", res.data);
                        // var dedata = decryptData(res.data);
                        if (res.data.error) {
                            toastError(res.data.error);
                            return "";
                        }
                        getuserDB();
                        toastSuccess("Transaction done");
                        settrdone(false);
                        navigate("/");
                    });


            } catch (error) {
                console.log("Error", error);
                toastError("Transaction declined");
                settrdone(false);
                return;
            }

        }
        // if (Number(library.utils.fromWei(allowance, "ether")) < total_tokens) {
        main()
        return
        if (Number(ethers.utils.formatEther(allowance)) < amount) {
            // if (false) {
            try {
                var approv = await token_contract.approve(
                    settings.contract,
                    ethers.utils.parseEther("100000000")
                );
                const receipt = await approv.wait();
                console.log(receipt);
                toastSuccess("Token Approved");
                main();
            } catch (error) {
                console.log("error", error);
                toastError("Token not approved");
                settrdone(false);
                return;
            }
        } else {
            main();
        }
    };
    // useEffect(() => {
    //     if (dbuser !== null) {
    //         if (dbuser.ref_address !== null) {
    //             setreferral(dbuser.ref_address);
    //         }
    //     }
    // }, [dbuser, account]);
    useEffect(() => {
        if (dbuser !== null) {
            if (dbuser.ref_address !== null) {
                setreferral(dbuser.ref_address);
            }
        } else {
            setreferral(link_ref_address);
        }
    }, [link_ref_address, dbuser, account]);



    return (
        <>
            <div className="main-wrapper">
                <Header />
                {/* <LeftSidebar /> */}
                <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="middle-wrap">
                                <div className="card w-100 bg-white shadow-xs p-0 mb-4">
                                    <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                        <Link to="/dashboard" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white" /></Link>
                                        <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Stake</h4>
                                    </div>
                                    <div className="card-body w-100 border-0 mb-0">
                                        <form action="#">
                                            <div className="row justify-content-center">
                                                <div className="col-lg-8 ">
                                                    <div className="form-group">
                                                        <div className='d-flex align-items-center'>
                                                            <figure className="avatar me-3"><img src="../assets/images/user-12.png" alt="image" className="shadow-sm rounded-circle w50" /></figure>
                                                            <div className=''>
                                                                <label className="mont-font fw-600 font-xsss">Address</label>
                                                                <p>{account} <i className='ti-clipboard' /></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-between'>
                                                        <label className="mont-font fw-600 font-xsss">Balance</label>
                                                        <span className='bg-current rounded-xl p-0 py-2 px-4 lh-24 text-center text-white'>{balance} LMC</span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-8 mb-3">
                                                    <div className="form-group">
                                                        <label className="mont-font fw-600 font-xsss">Mentor address</label>
                                                        <div className="form-group">
                                                            {dbuser !== null ? (
                                                                dbuser.ref_address !== null ? (
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        value={referral}
                                                                    />
                                                                ) : (
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        placeholder="Enter Referral Address"
                                                                        value={referral}
                                                                        onChange={(e) => { setreferral(e.target.value); }
                                                                        }
                                                                    />
                                                                )
                                                            ) : (
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder="Enter Referral Address"
                                                                    value={referral}
                                                                    onChange={(e) => { setreferral(e.target.value); }
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className=' pt-3'>
                                                        <div className="form-group ">
                                                            <label className="mont-font fw-600 font-sm">Affiliate</label>
                                                            <div className='d-flex justify-content-between'>
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                                    <label class="form-check-label" for="flexRadioDefault1">
                                                                        Presale
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                                                    <label class="form-check-label" for="flexRadioDefault2">
                                                                        Non Presale
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 my-3 d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <label className="mont-font fw-600 font-xsss">Select Package</label>
                                                            <div class="dropdown show">
                                                                <button class="btn bg-current dropdown-toggle text-white" type="button" onClick={() => setShowPackage(!showPackage)}>
                                                                    {spkg !== null ? spkg.name : 'Select Package'}

                                                                </button>
                                                                <div class={showPackage ? "dropdown-menu show" : "dropdown-menu"} aria-labelledby="dropdownMenuButton">
                                                                    {pkgs.map((item, i) => {
                                                                        return (
                                                                            <a class="dropdown-item" type='button' onClick={() => { setShowPackage(!showPackage); setspkg(item) }}>{item.name}</a>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <ul className="d-flex align-items-center justify-content-center mt-1">
                                                            <li className="m-2">
                                                                <span className="font-xss mt-1 text-grey d-block fw-bold">Staking Amount</span>
                                                                <h4 className="fw-700 font-lg">${spkg !== null ? spkg.amount : '0'}</h4>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <ul className="d-flex align-items-center justify-content-center mt-1">
                                                        <li className="m-2">
                                                            <h4 className="fw-700 font-lg">{spkg !== null ? spkg.amount : '0'} <sub>LMC</sub></h4>
                                                            <span className="font-xss mt-1 text-grey d-block fw-bold">Stake Token</span>
                                                        </li>
                                                    </ul>
                                                    {hash ? (
                                                        <ul className="d-flex align-items-center justify-content-center mt-1">
                                                            <li className="m-2" ><h4 className="fw-700 font-sm">Tnx Hash <a className="font-xss fw-500 mt-1 text-grey-500 d-block text-break" target='_blank' href={`${process.env.REACT_APP_EXPLORER}tx/${hash}`}> #: {formatAddress(hash)}</a></h4></li>
                                                        </ul>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-lg-12 mb-0 mt-2 ps-0 d-flex justify-content-center">
                                                {trdone ? (
                                                    <div className='text-center'>
                                                        <img
                                                            className="border-0"
                                                            src="../assets/images/loader.gif"
                                                            alt="loading"
                                                            height={100}
                                                            width={100}
                                                        />
                                                    </div>
                                                ) : (
                                                    <a type="button" onClick={() => onDeposit()} className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">Stake</a>
                                                )}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}